<template>
    <div class="page">
        <div class="content">
            <Form :model="formItem" label-position="left" :label-width="80" style="width: 400px;min-height: 70vh;">
                <FormItem label="原密码">
                    <Input v-model="formItem.old_pwd" type="password" placeholder="请输入原密码"></Input>
                </FormItem>
                <FormItem label="新密码">
                    <Input v-model="formItem.new_pwd" type="password" placeholder="请输入新密码"></Input>
                </FormItem>
                <FormItem label="再次输入">
                    <Input v-model="again_pwd" type="password" placeholder="请再次输入新密码"></Input>
                </FormItem>
            </Form>
            <div class="foot">
                <Button type="primary" style="background: #296FE1;" @click="addBtn">保存</Button>
            </div>
        </div>
    </div>
</template>

<script>
import { loginModify } from "@/api/index";
export default {
    name: "password",
    data() {
        return {
            again_pwd: "",
            formItem: {
                old_pwd: "",
                new_pwd: "",
            },
        };
    },
    methods: {
        addBtn() {
            if (this.formItem.new_pwd == this.again_pwd) {
                loginModify(this.formItem)
                    .then((res) => {
                        this.$Message.success(res.msg);
                        this.formItem.old_pwd = "";
                        this.formItem.new_pwd = "";
                        this.again_pwd = "";
                        this.$router.push({ path: "/" });
                    })
                    .catch((err) => {
                        this.$Message.error(err.msg);
                    });
            } else {
                this.$Message.error("两次密码输入不一致");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.page{
    width: 100%;
    height: 100%;
    background-color: #E9F0F2;
    padding: 20px;
}
.content{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 40px;
}
.foot{
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    margin: 10px 0;
    text-align: center;
}
</style>